<template>
  <div>
    <b-modal
      ref="add-profile-modal-1"
      centered
      hide-footer
      hide-header
      class="position-relative"
    >
      <div class="d-flex justify-content-center align-content-center align-items-center modal1">
        <b-row
          class="d-block"
        >
          <div class="closediv">
            <b-button
              variant="transparent"
              class="closebtn"
              @click="hide()"
            >
              <feather-icon
                icon="XIcon"
                class="cursor-pointer"
                size="16"
              />
            </b-button>
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center mt-2 mb-2">
            <span class="font-weight-bolder heading1">
              Add Profile
            </span>
          </div>
          <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center position-relative">
            <b-img
              :src="userData ? userData : require('@/assets/images/simiicons/Group 13297.svg')"
              alt="data.json"
              :class="userData ? 'userProfileImage' : null"
            />
            <span class="upload">
              <b-button
                variant="link"
                @click="inputFileClicked"
              >
                <b-img
                  :src="require('@/assets/images/simiicons/Add (1).svg')"
                  rounded
                  alt="data.json"
                />
              </b-button>
            </span>
          </div>
          <div class="d-inline-block m-1 d-flex justify-content-center align-content-center align-items-center">
            <div class="form">
              <validation-observer ref="addProfileForm">
                <b-form
                  class="mt-2"
                  @submit.prevent
                >
                  <input
                    id="fileUpload"
                    type="file"
                    accept="image/*"
                    hidden
                    @change="uploadImage($event)"
                  >
                  <div>
                    <b-form-group
                      text-bolder
                      label="Profile Name"
                      class="label"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="userProfile"
                        rules="required"
                      >
                        <b-form-input
                          id="input-1"
                          v-model="userProfile"
                          placeholder="Enter Profile Name"
                          trim
                          class="input1"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <!-- submit buttons -->
                  <div
                    class="form-group block mb-2"
                  >
                    <b-button
                      class="buttonss"
                      block
                      variant="primary"
                      :disabled="!active"
                      type="submit"
                      @click="addProfile($event)"
                    >
                      <div
                        v-if="spinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                        />
                      </div>
                      Add
                    </b-button>
                    <div
                      v-if="errorshow"
                      class="small px-2"
                    >
                      Maximum Size of file should be 2 Mb
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BImg,
  BForm,
  BFormInput,
  BFormGroup,
  VBModal,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BImg,
    BForm,
    BFormInput,
    BFormGroup,
    BModal,
    BRow,
    BSpinner,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      userProfile: '',
      userImage: null,
      active: true,
      spinner: false,
      errorshow: false,
      userData: '',
      // validation
      required,
    }
  },
  methods: {
    show() {
      this.$refs['add-profile-modal-1'].show()
      this.userProfile = ''
      this.userImage = null
      this.userData = null
    },
    hide() {
      this.$refs['add-profile-modal-1'].hide()
    },
    addProfile() {
      this.$refs.addProfileForm.validate().then(async success => {
        if (success) {
          this.spinner = true
          this.active = false
          await this.addProfiles()
          this.spinner = false
          this.active = true
          await this.hide()
          this.$emit('getAllProfiles')
          this.userProfile = ''
          this.userImage = null
        }
      })
    },
    inputFileClicked(event) {
      document.getElementById('fileUpload').click(event)
    },
    uploadImage(event) {
      const obj = event.target.files[0]
      this.userImage = obj
      this.userData = URL.createObjectURL(obj)
    },
    async addProfiles() {
      const formData = new FormData()
      formData.append('profile_name', this.userProfile)
      formData.append('image', this.userImage)
      return this.$axios
        .post('user/profile',
          formData)
        .then(() => {
        }).catch(() => (this.$swal('Profile limit exceeded, can not create profile')))
    },
  },
}
</script>
<style scoped>
.form{
  padding-inline: 10px;
}
.add{
  position: relative;
}
.upload{
  position: absolute;
  bottom: 20px;
  right: 54px;
}
.head{
font-size: 14px;
line-height: 24px;
color: #1f58b5;
font-weight: 600;
font-family: "Montserrat";
}
.spinner{
  text-align: center;
  z-index: 1070;
}
.modal1{
  padding: 0 !important;
}
.input1{
  width: 300px;
  background-color: transparent;
  font-size: 16px;
  line-height: 21px;
  color: #6e6b7b;
  font-weight: 400;
  font-family: "Montserrat";
}
.userProfileImage{
  width: 175px;
  height: 175px;
  border-radius: 100%;
  background-color: #fff;
}
</style>
