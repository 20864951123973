<template>
  <section>
    <div class="d-flex justify-content-end mt-0">
      <b-tooltip
        ref="tooltip"
        :disabled.sync="addProfileEnabled"
        target="tooltip-button-disable"
      >
        <span>Add Profile Limit is Three Only! </span>
      </b-tooltip>
      <div id="tooltip-button-disable">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover
          variant="primary"
          :disabled="profileLoading || !addProfileEnabled"
          style="margin-top: -3rem"
          @click="openProfileModel"
        >
          Add Profile
        </b-button>
      </div>
      <AddProfile
        ref="add-profile-modal"
        @getAllProfiles="getAllProfiles"
      />
      <EditProfile
        :id="editProfileData.id"
        :key="editProfileData.id"
        ref="edit-profile-modal"
        :src="editProfileData.src"
        :title="editProfileData.title"
        @getAllProfiles="getAllProfiles"
      />
      <DeleteProfile
        :id="id"
        ref="delete-profile-modal"
        @getAllProfiles="getAllProfiles"
      />
    </div>
    <div>
      <app-collapse
        accordion
        type="margin"
        class="mt-2"
      >
        <b-skeleton-wrapper :loading="profileLoading">
          <template #loading>
            <b-skeleton width="100%" />
            <b-skeleton width="75%" />
            <b-skeleton width="50%" />
          </template>
          <profile-collapse-item
            v-for="(profileData, index) in allProfileDetail"
            :id="profileData.id"
            :key="index"
            :title="profileData.name"
            :is-avatar="isAvatar"
            :src="profileData.image + '&key=' + profileTableKey"
            icons="TrashIcon"
            @editProfile="editProfile"
            @deleteProfile="deleteProfile"
          >
            <div class="heading-elements">
              <b-table
                :items="profileData.allowed_apps"
                responsive
                :fields="tableColumns"
                show-empty
              >
                <!-- Column: name -->
                <template
                  #cell(ALLOWED-APPS)="data"
                  class="p-3"
                >
                  <span
                    class="text-nowrap"
                  >
                    {{ data.item.name }}
                  </span>
                  <div
                    v-if="data.item.is_on_home_screen"
                    class="position font-weight-bold float-right"
                  >
                    Home
                  </div>
                </template>
              </b-table>
            </div>
            <div class="heading-elements">
              <b-table
                :items="profileData.widgets"
                responsive
                :fields="widgesColumn"
                show-empty
              >
                <!-- Column: name -->
                <template
                  #cell(ALLOWED-WIDGETS)="data"
                >
                  <span class="text-nowrap">
                    {{ data.item.name }}
                  </span>
                </template>
              </b-table>
            </div>
            <div
              class="heading-elements"
            >
              <b-table
                :items="allHomeScreenViews"
                responsive
                :fields="viewsColumn"
                show-empty
              >
                <!-- Column: name -->
                <template #cell(VIEWS)="data">
                  <span class="text-nowrap">
                    {{ data.item.name }}
                  </span>
                  <div class="float-right">
                    <div
                      v-if="customViewsSwitchLoaders[`${profileData.id}-${data.item.id}`]"
                      style="width: 75px;"
                      class="spinner text-center"
                    >
                      <b-spinner
                        variant="primary"
                        small
                      />
                    </div>
                    <vs-switch
                      v-else
                      v-model="customViewsSwitchStatuses[`${profileData.id}-${data.item.id}`]"
                      circle
                      class="switch"
                      :style="`background-color: ${customViewsSwitchStatuses[`${profileData.id}-${data.item.id}`] ? '#00E265' : '#FF0000'} `"
                      @input="updateSwitchState(profileData.id, data.item.id, customViewsSwitchStatuses[`${profileData.id}-${data.item.id}`])"
                    >
                      <span
                        slot="on"
                        style="font-weight: bold"
                      >Disable</span>
                      <span
                        slot="off"
                        style="font-weight: bold"
                      >Enable</span>
                    </vs-switch>
                  </div>
                </template></b-table>
            </div>
          </profile-collapse-item>
        </b-skeleton-wrapper>
      </app-collapse>
    </div>
  </section>
</template>

<script>
import {
  BButton, BTable, BSkeletonWrapper, BSkeleton, BSpinner, VBTooltip, BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import Vue from 'vue'
import AddProfile from '@/components/profiles/AddProfile.vue'
import AppCollapse from '@/components/app-collapse/AppCollapse.vue'
import ProfileCollapseItem from '@/components/profiles/ProfileCollapseItem.vue'
import EditProfile from '@/components/profiles/EditProfile.vue'
import DeleteProfile from '@/components/profiles/DeleteProfile.vue'

Vue.use(Vuesax)
Vue.directive('ripple', Ripple)

export default {
  components: {
    ProfileCollapseItem,
    DeleteProfile,
    EditProfile,
    AppCollapse,
    AddProfile,
    BButton,
    BTable,
    BSkeletonWrapper,
    BSkeleton,
    BSpinner,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      profileId: '',
      isAvatar: true,
      allHomeScreenViews: [],
      editProfileData: {
      },
      id: '',
      customViewsSwitchStatuses: [],
      customViewsSwitchLoaders: [],
      profileTableKey: 0,
      switch2: true,
      profilesData: '',
      allProfileDetail: '',
      profileLoading: false,
      tableColumns: [{ key: 'ALLOWED-APPS' }],
      widgesColumn: [{ key: 'ALLOWED-WIDGETS' }],
      viewsColumn: [{ key: 'VIEWS' }],
      // validation
      required,
    }
  },
  computed: {
    addProfileEnabled: {
      get() {
        return this.allProfileDetail.length < 3
      },
      set() {
      //
      },
    },
  },
  async mounted() {
    this.profileLoading = true
    await this.getAllProfiles()
    await this.getAllHomeScreenViews()
    this.profileLoading = false
  },
  methods: {
    async getAllHomeScreenViews() {
      await this.$axios.get('home-screen-views')
        .then(({ data }) => {
          this.allHomeScreenViews = data.data
        }).catch(error => ({ error }))
    },
    openProfileModel() {
      this.$refs['add-profile-modal'].show()
    },
    editProfile(object) {
      this.editProfileData = object
      const self = this
      setTimeout(() => {
        self.$refs['edit-profile-modal'].show()
      }, 0)
    },
    deleteProfile(obj) {
      this.id = obj.id
      this.$refs['delete-profile-modal'].show()
    },
    async updateSwitchState(profileId, homeScreenViewsId, status) {
      Vue.set(this.customViewsSwitchLoaders, `${profileId}-${homeScreenViewsId}`, true)
      const data = {
        profileId,
        home_screen_views_id: homeScreenViewsId,
      }
      if (status === true) {
        await this.$axios.post('profile-home-screen', data)
          .then(async () => {
            await this.getAllProfiles()
          }).catch(() => (this.$swal('Profile view already exists!')))
      } else {
        await this.$axios.delete('profile-home-screen', { data })
          .then(async () => {
            await this.getAllProfiles()
          }).catch(error => ({ error }))
      }
      Vue.set(this.customViewsSwitchLoaders, `${profileId}-${homeScreenViewsId}`, false)
    },
    setCustomViewsStatuses(profiles) {
      // eslint-disable-next-line no-restricted-syntax
      for (const profile of profiles) {
        // eslint-disable-next-line no-restricted-syntax
        for (const profileHasHomeScreenView of profile.profile_has_home_screen_views) {
          Vue.set(this.customViewsSwitchStatuses, `${profile.id}-${profileHasHomeScreenView.home_screen_view.id}`, true)
        }
      }
    },
    async getAllProfiles() {
      return this.$axios
        .get('user/profile/profiles')
        .then(({ data }) => {
          const { data: dataRes } = data || {}
          this.allProfileDetail = dataRes
          this.profileId = this.allProfileDetail.map(d => d.id)
          this.setCustomViewsStatuses(this.allProfileDetail)
        }).catch(error => ({ error }))
    },
  },
}
</script>

<style scoped>
.position {
  background-color: rgba(40, 199, 111, 0.12);
  color: #28c76f;
  border-radius: 25px;
  margin: auto;
  display: inline-block;
  padding: 5px 15px;
}
.switch {
  border-radius: 20px;
  color: #FFF3E8;
}
body {
  margin: 0;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #1f58b5 ;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid #1f58b5 ;
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}

</style>
